import * as yup from 'yup';
import {
  SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION,
  SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS,
  SBAT_STATUS_ID_PENDING_SCHOOL_CONFIRMATION,
  SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW,
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
  SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION,
  SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE,
} from '../../../sbatStatuses';

import {
  ROLE_ID_SCHOOL,
  ROLE_ID_SBAT_OFFICER,
  ROLE_ID_ADMIN,
  ROLE_ID_SYSTEM,
} from '../../../roles';

import {
  stringValidation,
  dateValidation,
  dateAfterValidation,
  dateBetweenValidation,
  textValidation,
  postcodeValidation,
  phoneNumberValidation,
  emailValidation,
  booleanValidation,
  abnValidation,
  idSelectionValidation,
  arrayValidation,
  atLeastOneValidation,
  integerValidation,
  schoolYearValidation,
  nesaNumberValidation,
} from '../../form/ValidationSchema';

const fieldValidations = {
  // Student
  sd_nesa_number: nesaNumberValidation,
  sd_school_year: schoolYearValidation,
  sd_first_name: stringValidation,
  sd_last_name: stringValidation,
  sd_date_of_birth: dateValidation,
  sd_gender_id: idSelectionValidation,
  sd_street_address: textValidation,
  sd_suburb: stringValidation,
  sd_state_id: idSelectionValidation,
  sd_postcode: postcodeValidation,
  sd_phone: phoneNumberValidation,
  sd_mobile: phoneNumberValidation,
  sd_phone_or_mobile: atLeastOneValidation(
    ['sd_phone', 'sd_mobile'],
    'At least one number required'
  ),
  sd_email: emailValidation,
  sd_aboriginal_or_torres: integerValidation,
  // School
  hd_name: stringValidation,
  hd_suburb: stringValidation,
  hd_state_id: idSelectionValidation,
  hd_contact_name: stringValidation,
  hd_contact_phone: phoneNumberValidation,
  hd_contact_email: emailValidation,
  hd_school_sector_id: idSelectionValidation,
  hd_operational_directorate: stringValidation,
  hd_od_contact_email: emailValidation,
  hd_od_contact_name: stringValidation,
  hd_od_contact_phone: phoneNumberValidation,
  hd_od_contact_fax: phoneNumberValidation,
  hd_od_contact_mobile: phoneNumberValidation,
  hd_od_contact_phone_or_mobile: atLeastOneValidation(
    ['hd_od_contact_phone', 'hd_od_contact_mobile'],
    'At least one number required'
  ),
  // Employer
  ed_legal_name: stringValidation,
  ed_trading_name: stringValidation,
  ed_abn: abnValidation,
  ed_street_address: textValidation,
  ed_suburb: stringValidation,
  ed_state_id: idSelectionValidation,
  ed_postcode: postcodeValidation,
  ed_contact_name: stringValidation,
  ed_contact_fax: phoneNumberValidation,
  ed_contact_phone: phoneNumberValidation,
  ed_contact_mobile: phoneNumberValidation,
  ed_contact_phone_or_mobile: atLeastOneValidation(
    ['ed_contact_phone', 'ed_contact_mobile'],
    'At least one number required'
  ),
  ed_contact_email: emailValidation,
  ed_workplace_training_street_address: textValidation,
  ed_workplace_training_state_id: idSelectionValidation,
  ed_workplace_training_postcode: postcodeValidation,
  ed_workplace_supervisor_name: stringValidation,
  ed_workplace_supervisor_contact_number: phoneNumberValidation,
  ed_host_employer: integerValidation,
  ed_host_employer_trading_name: stringValidation,
  ed_regulated_trades_supervisor_name: stringValidation,
  ed_regulated_trades_supervisor_license_no: stringValidation,
  // ANP
  ad_name: stringValidation,
  ad_suburb: stringValidation,
  ad_state_id: idSelectionValidation,
  ad_postcode: postcodeValidation,
  ad_contact_name: stringValidation,
  ad_contact_phone: phoneNumberValidation,
  ad_contact_mobile: phoneNumberValidation,
  ad_contact_phone_or_mobile: atLeastOneValidation(
    ['ad_contact_phone', 'ad_contact_mobile'],
    'At least one number required'
  ),
  ad_contact_email: emailValidation,
  // Training
  td_contract_type_id: idSelectionValidation,
  td_employment_type_id: idSelectionValidation,
  td_employment_school_based: booleanValidation,
  td_hours_per_week: stringValidation,
  td_sbat_hsc_year: stringValidation,
  td_tc_start_date: dateValidation,
  td_tc_end_date: dateAfterValidation(
    'td_tc_start_date',
    'Must be after TC Start Date'
  ),
  td_vocation_title: stringValidation,
  td_vto_id: stringValidation,
  td_qualification_title: stringValidation,
  td_qualification_level: stringValidation,
  td_national_code: stringValidation,
  td_mode_of_delivery_ids: arrayValidation,
  td_rto_classroom_training_street_address: stringValidation,
  td_rto_classroom_training_state_id: idSelectionValidation,
  td_rto_classroom_training_postcode: postcodeValidation,
  td_funding_source_id: idSelectionValidation,
  td_disability: integerValidation,
  td_daaws: integerValidation,
  td_commence_yr10: booleanValidation,
  td_commence_yr11: booleanValidation,
  td_commence_yr12: booleanValidation,
  td_commence_notes: stringValidation,
  td_learning_needs: booleanValidation,
  td_atar: integerValidation,
  // RTO1
  r1_svet: booleanValidation,
  r1_start_date: dateBetweenValidation(
    'td_tc_start_date',
    'r1_end_date',
    'Must be between TC Dates'
  ),
  r1_end_date: dateBetweenValidation(
    'r1_start_date',
    'td_tc_end_date',
    'Must be between TC dates'
  ),
  r1_name: stringValidation,
  r1_trading_name: stringValidation,
  r1_abn: abnValidation,
  r1_contact_name: stringValidation,
  r1_contact_phone: phoneNumberValidation,
  r1_contact_fax: phoneNumberValidation,
  r1_contact_mobile: phoneNumberValidation,
  r1_contact_phone_or_mobile: atLeastOneValidation(
    ['r1_contact_phone', 'r1_contact_mobile'],
    'At least one number required'
  ),
  r1_contact_email: emailValidation,
  r1_national_code: stringValidation,
  // RTO2
  r2_start_date: dateBetweenValidation(
    'td_tc_start_date',
    'r2_end_date',
    'Must be between TC Dates'
  ),
  r2_end_date: dateBetweenValidation(
    'r2_start_date',
    'td_tc_end_date',
    'Must be between TC dates'
  ),
  r2_name: stringValidation,
  r2_trading_name: stringValidation,
  r2_abn: abnValidation,
  r2_contact_name: stringValidation,
  r2_contact_phone: phoneNumberValidation,
  r2_contact_fax: phoneNumberValidation,
  r2_contact_mobile: phoneNumberValidation,
  r2_contact_email: emailValidation,
  r2_contact_phone_or_mobile: atLeastOneValidation(
    ['r2_contact_phone', 'r2_contact_mobile'],
    'At least one number required'
  ),
  r2_national_code: stringValidation,
};

const statusFields = {
  [SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION]: {
    // Student
    sd_nesa_number: {
      requiredOnSubmit: false,
    },
    sd_school_year: {
      requiredOnSubmit: false,
    },
    sd_first_name: {
      requiredOnSubmit: true,
    },
    sd_last_name: {
      requiredOnSubmit: true,
    },
    sd_date_of_birth: {
      requiredOnSubmit: true,
    },
    sd_gender_id: {
      requiredOnSubmit: true,
    },
    sd_street_address: {
      requiredOnSubmit: true,
    },
    sd_suburb: {
      requiredOnSubmit: true,
    },
    sd_state_id: {
      requiredOnSubmit: true,
    },
    sd_postcode: {
      requiredOnSubmit: true,
    },
    sd_phone: {
      requiredOnSubmit: false,
    },
    sd_mobile: {
      requiredOnSubmit: false,
    },
    sd_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    sd_email: {
      requiredOnSubmit: true,
    },
    sd_aboriginal_or_torres: {
      requiredOnSubmit: true,
    },
    // School
    hd_name: {
      requiredOnSubmit: true,
    },
    hd_suburb: {
      requiredOnSubmit: true,
    },
    hd_state_id: {
      requiredOnSubmit: true,
    },
    hd_contact_name: {
      requiredOnSubmit: true,
    },
    hd_contact_phone: {
      requiredOnSubmit: true,
    },
    hd_contact_email: {
      requiredOnSubmit: true,
    },
    hd_school_sector_id: {
      requiredOnSubmit: true,
    },
    hd_operational_directorate: {
      requiredOnSubmit: true,
    },
    hd_od_contact_email: {
      requiredOnSubmit: true,
    },
    hd_od_contact_name: {
      requiredOnSubmit: true,
    },
    hd_od_contact_phone: {
      requiredOnSubmit: false,
    },
    hd_od_contact_fax: {
      requiredOnSubmit: false,
    },
    hd_od_contact_mobile: {
      requiredOnSubmit: false,
    },
    hd_od_contact_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    // Employer
    ed_legal_name: {
      requiredOnSubmit: false,
    },
    ed_trading_name: {
      requiredOnSubmit: false,
    },
    ed_abn: {
      requiredOnSubmit: false,
    },
    ed_street_address: {
      requiredOnSubmit: false,
    },
    ed_suburb: {
      requiredOnSubmit: false,
    },
    ed_state_id: {
      requiredOnSubmit: false,
    },
    ed_postcode: {
      requiredOnSubmit: false,
    },
    ed_contact_name: {
      requiredOnSubmit: false,
    },
    ed_contact_fax: {
      requiredOnSubmit: false,
    },
    ed_contact_phone: {
      requiredOnSubmit: false,
    },
    ed_contact_mobile: {
      requiredOnSubmit: false,
    },
    ed_contact_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    ed_contact_email: {
      requiredOnSubmit: true,
    },
    ed_workplace_training_street_address: {
      requiredOnSubmit: false,
    },
    ed_workplace_training_state_id: {
      requiredOnSubmit: false,
    },
    ed_workplace_training_postcode: {
      requiredOnSubmit: false,
    },
    ed_workplace_supervisor_name: {
      requiredOnSubmit: false,
    },
    ed_workplace_supervisor_contact_number: {
      requiredOnSubmit: false,
    },
    ed_host_employer: {
      requiredOnSubmit: false,
    },
    ed_host_employer_trading_name: {
      requiredOnSubmit: false,
    },
    ed_regulated_trades_supervisor_name: {
      requiredOnSubmit: false,
    },
    ed_regulated_trades_supervisor_license_no: {
      requiredOnSubmit: false,
    },
  },

  [SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS]: {
    ed_legal_name: {
      requiredOnSubmit: true,
    },
    ed_trading_name: {
      requiredOnSubmit: true,
    },
    ed_abn: {
      requiredOnSubmit: true,
    },
    ed_street_address: {
      requiredOnSubmit: true,
    },
    ed_suburb: {
      requiredOnSubmit: true,
    },
    ed_state_id: {
      requiredOnSubmit: true,
    },
    ed_postcode: {
      requiredOnSubmit: true,
    },
    ed_contact_name: {
      requiredOnSubmit: true,
    },
    ed_contact_fax: {
      requiredOnSubmit: false,
    },
    ed_contact_phone: {
      requiredOnSubmit: false,
    },
    ed_contact_mobile: {
      requiredOnSubmit: false,
    },
    ed_contact_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    ed_contact_email: {
      requiredOnSubmit: true,
    },
    ed_workplace_training_street_address: {
      requiredOnSubmit: true,
    },
    ed_workplace_training_state_id: {
      requiredOnSubmit: true,
    },
    ed_workplace_training_postcode: {
      requiredOnSubmit: true,
    },
    ed_workplace_supervisor_name: {
      requiredOnSubmit: true,
    },
    ed_workplace_supervisor_contact_number: {
      requiredOnSubmit: true,
    },
    ed_host_employer: {
      requiredOnSubmit: true,
    },
    ed_host_employer_trading_name: {
      requiredOnSubmit: false,
      requiredOnSubmitRule: (r) =>
        r.when('ed_host_employer', {
          is: (val) => val == true,
          then: (schema) => schema.required('Required'),
        }),
    },
    ed_regulated_trades_supervisor_name: {
      requiredOnSubmit: false,
    },
    ed_regulated_trades_supervisor_license_no: {
      requiredOnSubmit: false,
    },
    // ANP
    ad_name: {
      requiredOnSubmit: true,
    },
    ad_suburb: {
      requiredOnSubmit: true,
    },
    ad_state_id: {
      requiredOnSubmit: true,
    },
    ad_postcode: {
      requiredOnSubmit: true,
    },
    ad_contact_name: {
      requiredOnSubmit: true,
    },
    ad_contact_phone: {
      requiredOnSubmit: false,
    },
    ad_contact_mobile: {
      requiredOnSubmit: false,
    },
    ad_contact_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    ad_contact_email: {
      requiredOnSubmit: true,
    },
  },
  [SBAT_STATUS_ID_PENDING_SCHOOL_CONFIRMATION]: {},

  [SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW]: {
    td_contract_type_id: {
      requiredOnSubmit: true,
    },
    td_employment_type_id: {
      requiredOnSubmit: true,
    },
    td_employment_school_based: {
      requiredOnSubmit: true,
    },
    td_hours_per_week: {
      requiredOnSubmit: true,
    },
    td_sbat_hsc_year: {
      requiredOnSubmit: true,
    },
    td_tc_start_date: {
      requiredOnSubmit: true,
    },
    td_tc_end_date: {
      requiredOnSubmit: true,
    },
    td_vocation_title: {
      requiredOnSubmit: true,
    },
    td_vto_id: {
      requiredOnSubmit: true,
    },
    td_qualification_title: {
      requiredOnSubmit: true,
    },
    td_qualification_level: {
      requiredOnSubmit: true,
    },
    td_national_code: {
      requiredOnSubmit: true,
    },
    td_mode_of_delivery_ids: {
      requiredOnSubmit: true,
    },
    td_rto_classroom_training_street_address: {
      requiredOnSubmit: true,
    },
    td_rto_classroom_training_state_id: {
      requiredOnSubmit: true,
    },
    td_rto_classroom_training_postcode: {
      requiredOnSubmit: true,
    },
    td_funding_source_id: {
      requiredOnSubmit: true,
    },
    td_disability: {
      requiredOnSubmit: true,
    },
    td_daaws: {
      requiredOnSubmit: true,
    },
    td_commence_yr10: {
      requiredOnSubmit: true,
    },
    td_commence_yr11: {
      requiredOnSubmit: true,
    },
    td_commence_yr12: {
      requiredOnSubmit: true,
    },
    td_commence_notes: {
      requiredOnSubmit: false,
    },
    td_learning_needs: {
      requiredOnSubmit: true,
    },
    td_atar: {
      requiredOnSubmit: true,
    },
    // RTO1
    r1_svet: {
      requiredOnSubmit: true,
    },
    r1_start_date: {
      requiredOnSubmit: true,
    },
    r1_end_date: {
      requiredOnSubmit: true,
    },
    r1_name: {
      requiredOnSubmit: true,
    },
    r1_trading_name: {
      requiredOnSubmit: true,
    },
    r1_abn: {
      requiredOnSubmit: true,
    },
    r1_contact_name: {
      requiredOnSubmit: true,
    },
    r1_contact_phone: {
      requiredOnSubmit: false,
    },
    r1_contact_fax: {
      requiredOnSubmit: false,
    },
    r1_contact_mobile: {
      requiredOnSubmit: false,
    },
    r1_contact_phone_or_mobile: {
      requiredOnSubmit: false,
    },
    r1_contact_email: {
      requiredOnSubmit: true,
    },
    r1_national_code: {
      requiredOnSubmit: true,
    },
    // RTO2
    r2_start_date: {
      requiredOnSubmit: false,
    },
    r2_end_date: {
      requiredOnSubmit: false,
    },
    r2_name: {
      requiredOnSubmit: false,
    },
    r2_trading_name: {
      requiredOnSubmit: false,
    },
    r2_abn: {
      requiredOnSubmit: false,
    },
    r2_contact_name: {
      requiredOnSubmit: false,
    },
    r2_contact_phone: {
      requiredOnSubmit: false,
    },
    r2_contact_fax: {
      requiredOnSubmit: false,
    },
    r2_contact_mobile: {
      requiredOnSubmit: false,
    },
    r2_contact_email: {
      requiredOnSubmit: false,
    },
    r2_national_code: {
      requiredOnSubmit: false,
    },
  },

  [SBAT_STATUS_ID_PENDING_RTO_REVIEW]: {},
  [SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION]: {},
  [SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW]: {},
  [SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION]: {},
  [SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE]: {},
};

const getStartEndStatusId = (statusId, roleId) => {
  // These roles can edit previous status sections, so we build up our
  // validation schema all the fields from previous sections. Later
  // sections will overwrite earlier ones.
  const startStatusId = [
    ROLE_ID_SCHOOL,
    ROLE_ID_SBAT_OFFICER,
    ROLE_ID_ADMIN,
    ROLE_ID_SYSTEM,
  ].includes(roleId)
    ? 1
    : statusId;
  return { startStatusId, endStatusId: statusId };
};

const getTrainingPlanRequiredOnSubmit = (statusId, roleId) => {
  const { startStatusId, endStatusId } = getStartEndStatusId(statusId, roleId);
  let validationShape = {};

  for (let i = startStatusId; i <= endStatusId; i++) {
    validationShape = Object.keys(statusFields[i]).reduce((carry, fieldKey) => {
      carry[fieldKey] = statusFields[i][fieldKey].requiredOnSubmit;
      return carry;
    }, validationShape);
  }
  return validationShape;
};

const getTrainingPlanValidationSchema = (statusId, roleId) => {
  const { startStatusId, endStatusId } = getStartEndStatusId(statusId, roleId);
  let validationShape = {};

  for (let i = startStatusId; i <= endStatusId; i++) {
    validationShape = Object.keys(statusFields[i]).reduce((carry, fieldKey) => {
      const validationSchema = fieldValidations[fieldKey].getValidationSchema();
      carry[fieldKey] = fieldValidations[fieldKey]
        .getTypeSchema()
        .when('$save', {
          is: true,
          then: validationSchema,
          otherwise: statusFields[i][fieldKey].requiredOnSubmit
            ? validationSchema.required('Required')
            : statusFields[i][fieldKey].requiredOnSubmitRule
            ? statusFields[i][fieldKey].requiredOnSubmitRule(validationSchema)
            : validationSchema,
        });
      return carry;
    }, validationShape);
  }

  return yup.object().shape(validationShape);
};

export default getTrainingPlanValidationSchema;

export { getTrainingPlanRequiredOnSubmit };
