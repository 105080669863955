import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Controller } from 'react-hook-form';
import MaterialSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  required: {
    '& fieldset': {
      borderColor: theme.palette.required.border,
    },
  },
}));

const SelectBooleanInput = ({
  // options,
  control,
  defaultValue,
  name,
  label,
  hasError,
  errorText,
  disabled = false,
  rules = {},
  requiredOnSubmit = false,
}) => {
  const classes = useStyles();

  const options = [
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
  ];

  const initialValue =
    defaultValue === null || defaultValue === undefined || defaultValue === ''
      ? ''
      : defaultValue === true
      ? 1
      : 0;

  return (
    <FormControl error={hasError} fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Controller
        rules={rules}
        as={
          <MaterialSelect
            className={requiredOnSubmit ? classes.required : ''}
            disabled={Boolean(disabled)}
            label={label}
            //onChange={(x) => console.log(x.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </MaterialSelect>
        }
        name={name}
        control={control}
        defaultValue={initialValue}
      />
      {hasError && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBooleanInput;
