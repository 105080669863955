export const SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION = 1;
export const SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS = 2;
export const SBAT_STATUS_ID_PENDING_SCHOOL_CONFIRMATION = 3;
export const SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW = 4;
export const SBAT_STATUS_ID_PENDING_RTO_REVIEW = 5;
export const SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW = 6;
export const SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION = 7;
export const SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION = 8;
export const SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE = 9;

// the order here is important
export const getSbatStatusIds = () => [
  SBAT_STATUS_ID_PENDING_INITIAL_SUBMISSION,
  SBAT_STATUS_ID_PENDING_EMPLOYER_DETAILS,
  SBAT_STATUS_ID_PENDING_SCHOOL_CONFIRMATION,
  SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW,
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  SBAT_STATUS_ID_PENDING_ANP_CONFIRMATION,
  SBAT_STATUS_ID_PENDING_SBAT_FINAL_REVIEW,
  SBAT_STATUS_ID_PENDING_SBAT_CONFIRMATION,
  SBAT_STATUS_ID_TRAINING_PLAN_COMPLETE,
];

export const getSbatStatusIndex = (statusId) =>
  getSbatStatusIds().indexOf(statusId);
