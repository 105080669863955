import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import TextInput from '../../../form/TextInput';
import SelectInput from '../../../form/SelectInput';
import SelectBooleanInput from '../../../form/SelectBooleanInput';
import CheckboxInput from '../../../form/CheckboxInput';
import DateInput from '../../../form/DateInput';
import MultiCheckboxInput from '../../../form/MultiCheckboxInput';
import TrainingPlanSection from '../TrainingPlanSection';
import AutoCompleteInputAsync from '../../../form/AutoCompleteInputAsync';
import api from '../../../../api';

const TrainingDetails = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  enabled,
  requiredOnSubmit,
}) => {
  const selectedQualification = enabled
    ? watch('td_qualification_title')
    : null;

  useEffect(() => {
    if (selectedQualification && selectedQualification.data) {
      setValue([
        { td_vocation_title: selectedQualification.data.vocation_title || '' },
        { td_vto_id: selectedQualification.data.vto_id || '' },
        {
          td_qualification_title:
            selectedQualification.data.qualification_title || '',
        },
        {
          td_qualification_level:
            selectedQualification.data.qualification_level || '',
        },
        { td_national_code: selectedQualification.data.national_code || '' },
      ]);
    }
  }, [selectedQualification, initialSbat]);

  return (
    <>
      <TrainingPlanSection title="Training Details">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="Contract Type"
              name="td_contract_type_id"
              options={trainingPlanOptions.contractTypes}
              control={control}
              defaultValue={initialSbat.td_contract_type_id || ''}
              hasError={Boolean(errors.td_contract_type_id)}
              errorText={errors?.td_contract_type_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_contract_type_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="Employment Type"
              name="td_employment_type_id"
              options={trainingPlanOptions.employmentTypes}
              control={control}
              defaultValue={initialSbat.td_employment_type_id || ''}
              hasError={Boolean(errors.td_employment_type_id)}
              errorText={errors?.td_employment_type_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_employment_type_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CheckboxInput
              register={register}
              defaultValue={initialSbat.td_employment_school_based}
              name="td_employment_school_based"
              label="School based"
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_employment_school_based}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="td_hours_per_week"
              label="Hours Per Week"
              defaultValue={initialSbat.td_hours_per_week || ''}
              control={control}
              hasError={Boolean(errors.td_hours_per_week)}
              errorText={errors?.td_hours_per_week?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_hours_per_week}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="td_sbat_hsc_year"
              label="SBAT HSC Year"
              defaultValue={initialSbat.td_sbat_hsc_year || ''}
              control={control}
              hasError={Boolean(errors.td_sbat_hsc_year)}
              errorText={errors?.td_sbat_hsc_year?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_sbat_hsc_year}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <DateInput
              label="TC Start Date"
              name="td_tc_start_date"
              defaultValue={initialSbat.td_tc_start_date || ''}
              control={control}
              hasError={Boolean(errors.td_tc_start_date)}
              errorText={errors?.td_tc_start_date?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_tc_start_date}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DateInput
              label="TC End Date"
              name="td_tc_end_date"
              defaultValue={initialSbat.td_tc_end_date || ''}
              control={control}
              hasError={Boolean(errors.td_tc_end_date)}
              errorText={errors?.td_tc_end_date?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_tc_end_date}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <AutoCompleteInputAsync
              label="Qualification Title"
              key="td_qualification_title"
              name="td_qualification_title"
              defaultValue={initialSbat.td_qualification_title || ''}
              control={control}
              hasError={Boolean(errors.td_qualification_title)}
              errorText={errors?.td_qualification_title?.message}
              freeSolo
              autoSelect
              onSuggest={api.suggestQualifications}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_qualification_title}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="td_qualification_level"
              label="Qualification Level"
              defaultValue={initialSbat.td_qualification_level || ''}
              control={control}
              hasError={Boolean(errors.td_qualification_level)}
              errorText={errors?.td_qualification_level?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_qualification_level}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="td_national_code"
              label="National Code"
              defaultValue={initialSbat.td_national_code || ''}
              control={control}
              hasError={Boolean(errors.td_national_code)}
              errorText={errors?.td_national_code?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_national_code}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <TextInput
              name="td_vocation_title"
              label="Vocation Title"
              defaultValue={initialSbat.td_vocation_title || ''}
              control={control}
              hasError={Boolean(errors.td_vocation_title)}
              errorText={errors?.td_vocation_title?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_vocation_title}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              name="td_vto_id"
              label="VTO ID"
              defaultValue={initialSbat.td_vto_id || ''}
              control={control}
              hasError={Boolean(errors.td_vto_id)}
              errorText={errors?.td_vto_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_vto_id}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MultiCheckboxInput
              control={control}
              defaultValue={initialSbat.td_mode_of_delivery_ids}
              options={trainingPlanOptions.modesOfDelivery}
              name="td_mode_of_delivery_ids"
              label="Mode of Delivery"
              hasError={Boolean(errors.td_mode_of_delivery_ids)}
              errorText={errors?.td_mode_of_delivery_ids?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_mode_of_delivery_ids}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="td_rto_classroom_training_street_address"
              label="RTO Classroom Training Street Address"
              defaultValue={
                initialSbat.td_rto_classroom_training_street_address || ''
              }
              control={control}
              hasError={Boolean(
                errors.td_rto_classroom_training_street_address
              )}
              errorText={
                errors?.td_rto_classroom_training_street_address?.message
              }
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.td_rto_classroom_training_street_address
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SelectInput
              label="RTO Classroom Training State"
              name="td_rto_classroom_training_state_id"
              options={trainingPlanOptions.states}
              control={control}
              defaultValue={
                initialSbat.td_rto_classroom_training_state_id || ''
              }
              hasError={Boolean(errors.td_rto_classroom_training_state_id)}
              errorText={errors?.td_rto_classroom_training_state_id?.message}
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.td_rto_classroom_training_state_id
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              name="td_rto_classroom_training_postcode"
              label="RTO Classroom Training Postcode"
              defaultValue={
                initialSbat.td_rto_classroom_training_postcode || ''
              }
              control={control}
              hasError={Boolean(errors.td_rto_classroom_training_postcode)}
              errorText={errors?.td_rto_classroom_training_postcode?.message}
              disabled={!enabled}
              requiredOnSubmit={
                requiredOnSubmit.td_rto_classroom_training_postcode
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="Funding Source"
              name="td_funding_source_id"
              options={trainingPlanOptions.fundingSources}
              control={control}
              defaultValue={initialSbat.td_funding_source_id || ''}
              hasError={Boolean(errors.td_funding_source_id)}
              errorText={errors?.td_funding_source_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_funding_source_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectBooleanInput
              name="td_disability"
              label="Disability"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.td_disability}
              hasError={Boolean(errors.td_disability)}
              errorText={errors?.td_disability?.message}
              requiredOnSubmit={requiredOnSubmit.td_disability}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectBooleanInput
              name="td_daaws"
              label="DAAWS"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.td_daaws}
              hasError={Boolean(errors.td_daaws)}
              errorText={errors?.td_daaws?.message}
              requiredOnSubmit={requiredOnSubmit.td_daaws}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Box>
              Year of School at contract commencement date of
              traineeship/apprenticeship
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid item xs={12} lg={12}>
              <CheckboxInput
                register={register}
                name="td_commence_yr10"
                label="Yr 10"
                defaultValue={initialSbat.td_commence_yr10}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.td_commence_yr10}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CheckboxInput
                register={register}
                name="td_commence_yr11"
                label="Yr 11"
                defaultValue={initialSbat.td_commence_yr11}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.td_commence_yr11}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CheckboxInput
                register={register}
                name="td_commence_yr12"
                label="Yr 12"
                defaultValue={initialSbat.td_commence_yr12}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.td_commence_yr12}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={9}>
            <TextInput
              name="td_commence_notes"
              label="Commence Notes"
              defaultValue={initialSbat.td_commence_notes || ''}
              control={control}
              hasError={Boolean(errors.td_commence_notes)}
              errorText={errors?.td_commence_notes?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_commence_notes}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <CheckboxInput
              register={register}
              name="td_learning_needs"
              label="Does the student have a disability, impairment, or specific learning needs?"
              defaultValue={initialSbat.td_learning_needs}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.td_learning_needs}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SelectBooleanInput
              name="td_atar"
              label="Does the course count in the calculation of an Australian Tertiary Admissions Ranking (ATAR)?"
              control={control}
              disabled={!enabled}
              defaultValue={initialSbat.td_atar}
              hasError={Boolean(errors.td_atar)}
              errorText={errors?.td_atar?.message}
              requiredOnSubmit={requiredOnSubmit.td_atar}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default TrainingDetails;
