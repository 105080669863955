import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import Box from '@material-ui/core/Box';

import {
  sbatFetchRequested,
  uploadTrainingPlanRequested,
  uploadTrainingPlanProposalRequested,
  sbatSaveRequested,
} from '../../redux/actions';
import Error404 from '../errors/Error404';
import Breadcrumbs from '../Breadcrumbs';
import TabSections from '../TabSections';
import TrainingPlanForm from './trainingPlan/TrainingPlanForm';
import TrainingPlanUpload from './trainingPlan/TrainingPlanUpload';
import SbatDetails from './SbatDetails';
import ResourceHistory from '../history/ResourceHistory';
import SbatProgress from './SbatProgress';
import {
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
  getSbatStatusIndex,
} from '../../sbatStatuses';
import config from '../../config';
import useAuthUser from '../../hooks/useAuthUser';
import TrainingPlanSettings from './trainingPlan/TrainingPlanAdmin';

const Sbat = () => {
  const dispatch = useDispatch();
  const sbat = useSelector((state) => state.sbats.sbat);
  const fetchingSbat = useSelector((state) => state.sbats.fetchingSbat);
  const sbatNotFound = useSelector((state) => state.sbats.sbatNotFound);
  const { id } = useParams();
  const {
    authUserIsOfficer,
    authUserIsAdmin,
    authUserIsSystem,
  } = useAuthUser();

  useEffect(() => {
    if (
      !fetchingSbat &&
      !sbatNotFound &&
      (!sbat || sbat.id.toString() !== id)
    ) {
      dispatch(sbatFetchRequested(id));
    }
  }, [dispatch, id, sbat, fetchingSbat, sbatNotFound]);

  return sbatNotFound ? (
    <Error404 />
  ) : (
    sbat && (
      <>
        <Breadcrumbs
          crumbs={[
            { text: 'SBATs', to: '/sbats', key: 'sbats' },
            {
              text: (
                <>
                  Manage SBAT:{' '}
                  <b>
                    {sbat.student.name.first} {sbat.student.name.last} -{' '}
                    {sbat.student.school.name}{' '}
                    {sbat.td_qualification_title
                      ? `- ${sbat.td_qualification_title}`
                      : ''}
                  </b>
                </>
              ),
              key: 'manage-sbat',
            },
          ]}
        />

        <SbatProgress sbat={sbat} />

        <TabSections
          sections={[
            {
              label: 'Details',
              icon: <InfoIcon />,
              contents: <SbatDetails sbat={sbat} />,
            },
            {
              label: 'Training Plan Form',
              selected:
                getSbatStatusIndex(sbat.status.id) <
                getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW),
              fullWidth: true,
              icon: <AssignmentIcon />,
              contents: <TrainingPlanForm initialSbat={sbat} />,
            },
            {
              label: 'Training Plan Proposal',
              selected:
                getSbatStatusIndex(sbat.status.id) >=
                getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW),
              icon: <AssignmentIcon />,
              contents: (
                <TrainingPlanUpload
                  trainingPlanDownloadUrl={
                    sbat.training_plan_proposal_file
                      ? `${config.apiBaseUrl}/sbats/file/${sbat.training_plan_proposal_file.id}`
                      : ''
                  }
                  uploadTrainingPlanRequestedAction={
                    uploadTrainingPlanProposalRequested
                  }
                  sbatSaveRequestedAction={sbatSaveRequested}
                  sbat={sbat}
                />
              ),
            },
            {
              label: 'Training Plan',
              selected:
                getSbatStatusIndex(sbat.status.id) >=
                getSbatStatusIndex(SBAT_STATUS_ID_PENDING_RTO_REVIEW),
              icon: <AssignmentIcon />,
              contents: (
                <TrainingPlanUpload
                  trainingPlanDownloadUrl={
                    sbat.training_plan_file
                      ? `${config.apiBaseUrl}/sbats/file/${sbat.training_plan_file.id}`
                      : ''
                  }
                  uploadTrainingPlanRequestedAction={
                    uploadTrainingPlanRequested
                  }
                  sbatSaveRequestedAction={sbatSaveRequested}
                  sbat={sbat}
                />
              ),
            },
            ...(authUserIsOfficer || authUserIsAdmin || authUserIsSystem
              ? [
                  {
                    label: 'Admin',
                    icon: <SettingsIcon />,
                    contents: <TrainingPlanSettings initialSbat={sbat} />,
                  },
                ]
              : []),
            {
              label: 'History',
              icon: <HistoryIcon />,
              contents: <ResourceHistory activities={sbat.activities} />,
            },
          ]}
        />
      </>
    )
  );
};

export default Sbat;
