import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import TextInput from '../../../form/TextInput';
import SelectInput from '../../../form/SelectInput';
import TrainingPlanSection from '../TrainingPlanSection';
import AutoCompleteInput from '../../../form/AutoCompleteInput';
import AutoCompleteInputAsync from '../../../form/AutoCompleteInputAsync';
import api from '../../../../api';

const AnpDetails = ({
  initialSbat,
  watch,
  setValue,
  control,
  errors,
  register,
  trainingPlanOptions,
  enabled,
  disableContactLookup,
  requiredOnSubmit,
}) => {
  const selectedContact = !disableContactLookup
    ? watch('ad_contact_email')
    : null;

  const anpNames = useSelector((state) => trainingPlanOptions.anpNames);

  useEffect(() => {
    if (
      !disableContactLookup &&
      selectedContact &&
      selectedContact.data &&
      (!initialSbat.ad_contact_email ||
        initialSbat.ad_contact_email !== selectedContact.data.email)
    ) {
      setValue([
        { ad_contact_name: selectedContact.data.name || '' },
        { ad_contact_phone: selectedContact.data.phone || '' },
        //{ ad_contact_fax: selectedContact.data.fax },
        { ad_contact_mobile: selectedContact.data.mobile || '' },
      ]);
    }
  }, [selectedContact, initialSbat]);

  return (
    <>
      <TrainingPlanSection title="Apprenticeship Network Provider (ANP)">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <AutoCompleteInput
              label="ANP Name"
              name="ad_name"
              defaultValue={initialSbat.ad_name || ''}
              control={control}
              hasError={Boolean(errors.ad_name)}
              errorText={errors?.ad_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_name}
              options={anpNames}
              freeSolo
              autoSelect
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Suburb"
              name="ad_suburb"
              defaultValue={initialSbat.ad_suburb || ''}
              control={control}
              hasError={Boolean(errors.ad_suburb)}
              errorText={errors?.ad_suburb?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_suburb}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SelectInput
              label="State"
              name="ad_state_id"
              options={trainingPlanOptions.states}
              control={control}
              defaultValue={initialSbat.ad_state_id || ''}
              hasError={Boolean(errors.ad_state_id)}
              errorText={errors?.ad_state_id?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_state_id}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              label="Postcode"
              name="ad_postcode"
              defaultValue={initialSbat.ad_postcode || ''}
              control={control}
              hasError={Boolean(errors.ad_postcode)}
              errorText={errors?.ad_postcode?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_postcode}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {!disableContactLookup ? (
              <AutoCompleteInputAsync
                label="Contact Email"
                key="ad_contact_email"
                name="ad_contact_email"
                defaultValue={initialSbat.ad_contact_email || ''}
                control={control}
                hasError={Boolean(errors.ad_contact_email)}
                errorText={errors?.ad_contact_email?.message}
                freeSolo
                autoSelect
                onSuggest={api.suggestContacts}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.ad_contact_email}
              />
            ) : (
              <TextInput
                name="ad_contact_email"
                label="Contact Email"
                defaultValue={initialSbat.ad_contact_email || ''}
                control={control}
                hasError={Boolean(errors.ad_contact_email)}
                errorText={errors?.ad_contact_email?.message}
                disabled={!enabled}
                requiredOnSubmit={requiredOnSubmit.ad_contact_email}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TextInput
              name="ad_contact_name"
              label="Contact Name"
              defaultValue={initialSbat.ad_contact_name || ''}
              control={control}
              hasError={Boolean(errors.ad_contact_name)}
              errorText={errors?.ad_contact_name?.message}
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_contact_name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ad_contact_phone"
              label="Contact Phone"
              defaultValue={initialSbat.ad_contact_phone || ''}
              control={control}
              hasError={Boolean(
                errors.ad_contact_phone || errors.ad_contact_phone_or_mobile
              )}
              errorText={
                errors?.ad_contact_phone?.message ??
                errors?.ad_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_contact_phone_or_mobile}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextInput
              name="ad_contact_mobile"
              label="Contact Mobile"
              defaultValue={initialSbat.ad_contact_mobile || ''}
              control={control}
              hasError={Boolean(
                errors.ad_contact_mobile || errors.ad_contact_phone_or_mobile
              )}
              errorText={
                errors?.ad_contact_mobile?.message ??
                errors?.ad_contact_phone_or_mobile?.message
              }
              disabled={!enabled}
              requiredOnSubmit={requiredOnSubmit.ad_contact_phone_or_mobile}
            />
          </Grid>
        </Grid>
      </TrainingPlanSection>
    </>
  );
};

export default AnpDetails;
