import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

import CheckboxInput from '../../form/CheckboxInput';
import SelectBooleanInput from '../../form/SelectBooleanInput';
import TextInput from '../../form/TextInput';
import {
  SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW,
  SBAT_STATUS_ID_PENDING_RTO_REVIEW,
} from '../../../sbatStatuses';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    marginRight: theme.spacing(2),
  },
}));

const TrainingPlanProgress = ({ savingSbat, register, sbat, onProgress }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const ProgressButton = (
    <Button
      type="button"
      name="validate"
      variant="contained"
      color="secondary"
      disabled={savingSbat}
      className={classes.submit}
      onClick={() => {
        handleModalClose();
        onProgress();
      }}
    >
      Progress
    </Button>
  );

  const body = (
    <Box className={classes.paper}>
      <h2 id="simple-modal-title">Progress Confirmation</h2>
      <p id="simple-modal-description">
        By progressing, a pre-filled TPP will be sent to RTO for sign up process
      </p>
      <Grid item xs={12} lg={12}>
        <CheckboxInput
          register={register}
          name="ad_notify_rto_review"
          label="Tick box for optional ANP Notification"
        />
      </Grid>
      {ProgressButton}
      <Button
        type="button"
        onClick={handleModalClose}
        name="cancel"
        variant="outlined"
        color="primary"
      >
        Cancel
      </Button>
    </Box>
  );
  return sbat.status.id === SBAT_STATUS_ID_PENDING_SBAT_INITIAL_REVIEW ? (
    <>
      <Button
        type="button"
        onClick={handleModalOpen}
        name="validate"
        variant="contained"
        color="secondary"
      >
        Progress
      </Button>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {body}
      </Modal>
    </>
  ) : (
    <>{ProgressButton}</>
  );
};

export default TrainingPlanProgress;
