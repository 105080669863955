import {
  SBATS_FETCH_REQUESTED,
  SBATS_FETCH_SUCCEEDED,
  SBAT_FETCH_REQUESTED,
  SBAT_FETCH_SUCCEEDED,
  SBAT_NOT_FOUND,
  SBAT_SAVE_REQUESTED,
  SBAT_SAVE_SUCCEEDED,
  UPDATE_SBATS_QUERY_SORT,
  UPDATE_SBATS_QUERY_SEARCH,
  UPDATE_SBATS_QUERY_FILTER,
  RESET_SBATS_QUERY,
  PENDING_SBATS_FETCH_REQUESTED,
  PENDING_SBATS_FETCH_SUCCEEDED,
  REDIRECT_TO_SBATS_WITH_QUERY,
  SET_SBATS_QUERY,
  TRAINING_PLAN_OPTIONS_FETCH_REQUESTED,
  TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED,
  UPLOAD_TRAINING_PLAN_REQUESTED,
  UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
} from './actionTypes';

export const sbatsFetchRequested = (query) => ({
  type: SBATS_FETCH_REQUESTED,
  query,
});

export const updateSbatsQuerySort = (id, asc) => ({
  type: UPDATE_SBATS_QUERY_SORT,
  id,
  asc,
});

export const updateSbatsQuerySearch = (search) => ({
  type: UPDATE_SBATS_QUERY_SEARCH,
  search,
});

export const updateSbatsQueryFilter = (filterId, optionValue) => ({
  type: UPDATE_SBATS_QUERY_FILTER,
  filterId,
  optionValue,
});

export const resetSbatsQuery = () => ({
  type: RESET_SBATS_QUERY,
});

export const sbatsFetchSucceeded = ({ page, next = null }) => ({
  type: SBATS_FETCH_SUCCEEDED,
  sbats: page,
  next,
});

export const sbatFetchRequested = (id) => ({
  type: SBAT_FETCH_REQUESTED,
  id,
});

export const sbatFetchSucceeded = ({ sbat }) => ({
  type: SBAT_FETCH_SUCCEEDED,
  sbat,
});

export const sbatNotFound = () => ({
  type: SBAT_NOT_FOUND,
});

export const sbatSaveRequested = ({ id, data, action }) => ({
  type: SBAT_SAVE_REQUESTED,
  id,
  data,
  action,
});

export const sbatSaveSucceeded = () => ({
  type: SBAT_SAVE_SUCCEEDED,
});

export const requestPendingSbatsFetch = () => ({
  type: PENDING_SBATS_FETCH_REQUESTED,
});

export const pendingSbatsFetchSucceeded = (sbats) => ({
  type: PENDING_SBATS_FETCH_SUCCEEDED,
  sbats,
});

export const redirectToSbatsWithQuery = (query) => ({
  type: REDIRECT_TO_SBATS_WITH_QUERY,
  query,
});

export const setSbatsQuery = (query) => ({
  type: SET_SBATS_QUERY,
  query,
});

export const trainingPlanOptionsFetchRequested = () => ({
  type: TRAINING_PLAN_OPTIONS_FETCH_REQUESTED,
});

export const trainingPlanOptionsFetchSucceeded = (trainingPlanOptions) => ({
  type: TRAINING_PLAN_OPTIONS_FETCH_SUCCEEDED,
  trainingPlanOptions,
});

export const uploadTrainingPlanRequested = ({ id, file }) => ({
  type: UPLOAD_TRAINING_PLAN_REQUESTED,
  id,
  file,
});

export const uploadTrainingPlanProposalRequested = ({ id, file }) => ({
  type: UPLOAD_TRAINING_PLAN_PROPOSAL_REQUESTED,
  id,
  file,
});
